import {
  StatisticsBlocksExceptWares,
  StatisticsPresentedDocumentItem,
  StatisticsWaresAndDocuments,
  StatisticsWareItem,
  StatisticsDeclaration,
} from '../types/statistics';

export class SetStatisticsDocument {
  static readonly type = '[Statistics] Set Statistics Document';
  constructor(public declaration: StatisticsDeclaration) {}
}

export class StatisticsUpdateBlock {
  static readonly type = '[Statistics] Update Block';
  constructor(public block: Partial<StatisticsBlocksExceptWares>) {}
}

export class StatisticsAddPresentedDocument {
  static readonly type = '[Statistics] Add Presented Document';
  constructor(
    public presentedDocumentItem: StatisticsPresentedDocumentItem[],
  ) {}
}

export class StatisticsUpdatePresentedDocuments {
  static readonly type = '[Statistics] Update Presented Documents';
  constructor(public presentedDocuments: StatisticsPresentedDocumentItem[]) {}
}

export class StatisticsReplacePresentedDocuments {
  static readonly type = '[Statistics] Replace Presented Documents';
  constructor(public presentedDocuments: StatisticsPresentedDocumentItem[]) {}
}

export class StatisticsReplaceWares {
  static readonly type = '[Statistics] Replace Wares';
  constructor(public wareItems: StatisticsWareItem[]) {}
}

export class StatisticsAddWareItem {
  static readonly type = '[Statistics] Add Ware Item';
  constructor(public wareItem: StatisticsWareItem) {}
}

export class StatisticsUpdateWareItems {
  static readonly type = '[Statistics] Update Ware Items';
  constructor(public wareItems: StatisticsWareItem[]) {}
}

export class StatisticsDeleteWareItems {
  static readonly type = '[Statistics] Delete Ware Item';
  constructor(public documentsAndWares: StatisticsWaresAndDocuments) {}
}

export class StatisticsReplaceWaresAndDocuments {
  static readonly type = '[Statistics] Replace Blocks';
  constructor(public documentsAndWares: StatisticsWaresAndDocuments) {}
}
